.graph_lines_wrapper____Yuk {
    display: flex
}

.graph_right_labels__6OJ02 {
    margin-top: 50px;
    padding-right: 10px;
    height: 187px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid #e3e3e8;
    padding-bottom: 30px
}

.graph_graph_amount_label__ME8m4 {
    font-size: 14px;
    color: #737985
}

.graph_graph_lines__AC5Nb {
    width: 100%;
    margin-top: 7px
}

.graph_line__hMapg {
    border-bottom: 1px solid #e3e3e8;
    height: 50px
}

.graph_chartItem__es2xi,.graph_chartItemSecond__rHPgB {
    position: absolute;
    width: 100px;
    height: 240px;
    background: linear-gradient(180deg,#069881,hsla(0,0%,100%,0) 35%);
    margin-left: 100px;
    margin-top: -80px
}

.graph_initial_ChartItem__vX6a3 {
    position: absolute;
    width: 100px;
    height: 100px;
    background: linear-gradient(180deg,#fff,rgba(254,210,65,0) 35%);
    margin-left: 100px;
    margin-top: -30px
}

.graph_chartItem_total__AoFas {
    position: absolute;
    width: 100px;
    background: #044f44;
    margin-left: 100px
}

.graph_calculatedEarnings__Vfh4r {
    position: absolute;
    background-color: #069881;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 24px;
    margin-top: -100px;
    margin-bottom: 35px
}

.graph_aboveChart_label__Vc6Hh,.graph_aboveChart_label_second__RAiHm {
    position: absolute;
    font-weight: 700;
    margin-left: 120px;
    margin-top: -105px
}

.graph_initial_aboveChart_label__6lOwm {
    position: absolute;
    font-weight: 700;
    margin-left: 120px;
    margin-top: -55px
}

.graph_underChart_label__o1OrX {
    color: #737985;
    font-size: 13px;
    margin-left: 133px;
    padding-top: 10px
}

.graph_underChart_label_months__0yCzN {
    color: #000;
    font-size: 13px;
    margin-left: 120px;
    padding-top: 10px;
    font-weight: 700
}

.graph_charts__h5TYh {
    display: flex
}

@media(max-width: 820px) {
    .graph_right_labels__6OJ02 {
        margin-left:20px
    }

    .graph_calculatedEarnings__Vfh4r {
        margin-left: 100px
    }
}

@media(max-width: 500px) {
    .graph_chartItem__es2xi {
        width:50px
    }

    .graph_chartItemSecond__rHPgB {
        width: 50px;
        margin-left: 50px
    }

    .graph_initial_ChartItem__vX6a3 {
        margin-left: 72px
    }

    .graph_chartItem_total__AoFas {
        width: 50px;
        margin-left: 50px
    }

    .graph_calculatedEarnings__Vfh4r {
        margin-left: 40px !important
    }

    .graph_aboveChart_label__Vc6Hh {
        margin-left: 90px
    }

    .graph_aboveChart_label_second__RAiHm {
        margin-left: 30px
    }

    .graph_underChart_label_months__0yCzN {
        margin-left: 40px
    }

    .graph_underChart_label__o1OrX {
        margin-left: 105px
    }
}

@media(max-width: 340px) {
    .graph_chartItem__es2xi {
        width:30px
    }

    .graph_chartItemSecond__rHPgB {
        width: 30px;
        margin-left: 20px
    }

    .graph_initial_ChartItem__vX6a3 {
        margin-left: 72px
    }

    .graph_chartItem_total__AoFas {
        width: 30px;
        margin-left: 20px
    }

    .graph_calculatedEarnings__Vfh4r {
        margin-left: -30px
    }

    .graph_aboveChart_label__Vc6Hh {
        margin-left: 90px;
        font-size: 13px
    }

    .graph_aboveChart_label_second__RAiHm {
        margin-left: 0;
        font-size: 13px
    }

    .graph_underChart_label_months__0yCzN {
        margin-left: 7px;
        font-size: 12px
    }

    .graph_underChart_label__o1OrX {
        margin-left: 95px
    }
}

.chart_wrapper__K6wh8 {
    width: 600px;
    height: 100%;
    color: #202945;
    border-radius: 15px !important;
    background-color: #fff;
}

.chart_income__QTl_B {
    display: flex;
    justify-content: space-between
}

.chart_footer__ln2wx {
    margin-top: 20px;
    font-size: 13px;
    color: #aaafb9
}

.chart_footer__ln2wx a {
    text-decoration: underline
}

.chart_projectedMaturity__QAdyn {
    margin-right: 10px
}

@media(max-width: 820px) {
    .chart_wrapper__K6wh8 {
        padding:0;
        width: 100%;
        min-width: 100%;
    }
}

@media(min-width: 821px)and (max-width:2999px) {
    .chart_wrapper__K6wh8 {
        padding:20px;
        width: 400px;
    }
}

.chart_orange_value__CjZ8F {
    font-weight: 700;
    color: #f39323
}

.chart_total_value__138Fo {
    font-weight: 700;
    color: #202945;
    font-size: 20px
}

.chart_title__ZlbWg {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 120%
}

.chart_chartWrapper__njTI_ {
    margin: 20px 0;
    height: 320px
}

.chart_textWrapper__lmEJK {
    margin-top: 40px;
    background-color: #f2f8fb;
    border-radius: 15px;
    padding: 20px 30px
}

.chart_income_title__R6EsD {
    font-weight: 700;
    font-size: 18px
}

.chart_income_description__GyEms {
    margin-top: 10px;
    font-size: 15px;
    color: #737985
}

.chart_textItem__dn7fj {
    position: relative;
    margin-bottom: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.chart_textItem__dn7fj:last-of-type {
    margin-bottom: 0
}

.chart_extraText__Q2Piz,.chart_text__oCDm5 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%
}

.chart_extraText__Q2Piz a,.chart_text__oCDm5 a {
    color: #f39323
}

.chart_value__HaqsF {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%
}

.chart_green__qbrOp {
    color: #71c179
}

.chart_extraText__Q2Piz {
    margin-top: 16px;
    color: #aaafb9
}

.chart_infoIcon__LRHht {
    margin-left: 10px;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

@media(max-width: 820px) {
    .chart_title__ZlbWg {
        padding-top:20px;
        padding-left: 20px
    }

    .chart_textWrapper__lmEJK {
        border-radius: 0
    }

    .chart_footer__ln2wx {
        padding: 0 20px 20px
    }
}

@media only screen and (min-width: 768px) {
    .chart_extraText__Q2Piz,.chart_text__oCDm5 {
        font-style:normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%
    }

    .chart_value__HaqsF {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%
    }

    .chart_textItem__dn7fj {
        margin-bottom: 10px
    }
}
